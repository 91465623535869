import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import AccessoriesPageContent from "../components/AccessoriesPageContent/AccessoriesPageContent";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { SanityAccessory } from "../model/buy";
import { CommonContext, SanityPage, SanityRawContent } from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query AccessoriesPage(
    $_id: String!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityAccessoriesPage(_id: { eq: $_id }) {
      _id
      _type
      title
      language
      _rawDescription(resolveReferences: { maxDepth: 5 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    listing: allSanityAccessory(
      sort: { fields: fields___lowerName }
      filter: { qaPass: { eq: true }, language: { eq: $language } }
    ) {
      nodes {
        _id
        _type
        name
        fields {
          excerpt
        }
        supplier {
          name
        }
        url
        compatibility
        subcategory {
          _id
          name
          category {
            _id
            name
          }
        }
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const AccessoriesPage = ({
  pageContext,
  data: { menus, page, listing },
  location
}: AccessoriesPageProps) => {
  const { title, _rawDescription: description } = page;

  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <AccessoriesPageContent
        title={title}
        description={description}
        accessories={listing.nodes}
      />
    </PageLayout>
  );
};

export default AccessoriesPage;

interface AccessoriesPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & {
      _rawDescription: SanityRawContent;
    };
    listing: { nodes: SanityAccessory[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
