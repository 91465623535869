import React from "react";
import TagInfo from "../../components/TagInfo/TagInfo";
import GetText from "../../i18n/GetText";
import { SanityAccessory } from "../../model/buy";

interface AccessoryTagInfoProps {
  className?: string;
  article: SanityAccessory;
}

const AccessoryTagInfo = ({ className, article }: AccessoryTagInfoProps) => {
  const tags: JSX.Element[][] = [];
  if (article.subcategory) {
    tags.push([
      <span key="category">{article.subcategory.category.name}</span>
    ]);
    tags.push([<span key="subcategory">{article.subcategory.name}</span>]);
  }
  if (article.compatibility.indexOf("microbitV2") < 0) {
    tags.push([
      <span key="compatibility">
        <GetText id="v1-only" />
      </span>
    ]);
  }
  return <TagInfo value={tags} className={className} />;
};
export default AccessoryTagInfo;
